import React from 'react';
import { a11y } from '../../../constants/a11y';
import { useTranslation } from 'react-i18next';

const FeedbackConfirmation = (props) => {
  try {
    const { t } = useTranslation();
    let { selectedAck, searchToggle, selectedSearchAcknowledgement, isSearchFeedback } = props;
    return (
      <div role={a11y.ROLES.ALERT} className="su_feedback_form">
        <div className="su__feedback-row su__text-center su__p-4">
          <div className="su__svg-ok">
            <svg
              className="ft-green-tick su__animate-zoom su__mr-2"
              height="100"
              width="100"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle className="su__circle" fill="#5bb543" cx="24" cy="24" r="22" />
              <path
                className="su__tick"
                fill="none"
                stroke="#FFF"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M14 27l5.917 4.917L34 17"
              />
            </svg>
          </div>
          <div className="su__feed-title su__font-14 su__text-black su__word-break">
            {!searchToggle && !isSearchFeedback && t(selectedAck)}
            {(searchToggle || isSearchFeedback) && selectedSearchAcknowledgement}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.log('Error in FeedbackConfirmation component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackConfirmation);
