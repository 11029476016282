import React from 'react';
import { useTranslation } from 'react-i18next';

const FeedbackEmail = (props) => {
  const customPlaceholderText= ""; // Added custom placeholder text
  try {
    const { t } = useTranslation();
    let { selectedPageTextFeedback, settextEmailFeedback, handleEmailIsValidated } = props;


    const handleChange = (e) => {
        const email = e.target.value;
        settextEmailFeedback(email); // Update the state with the new email
        handleEmailIsValidated(email); // Trigger email validation in the parent
    };



    return (
      <>
        <div className="su__pagerating-box su__my-3">
        <label htmlFor="">Email Address</label>
          <input
            className="su__input-feedack su__form-control su__w-100 su__su__font-14 su__text-black su__border su__radius-2"
            type="text"
            name="su__pagerating-input"
            id="su__pagerating-input"
            type="email"
            value={props.textEmailFeedback}
            onChange={handleChange}
          />
        </div>
      </>
    );
  } catch (e) {
    console.log('Error in FeedbackTextual component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackEmail);