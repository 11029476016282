/* eslint-disable react/prop-types */
import React from 'react';
import { Rating } from 'components/section-components/rating/index.jsx';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';
import variables from '../../../redux/variables';

const FeedbackSearch = (props) => {
  try {
    const { t } = useTranslation();
    let {
      searchFeedbackToggle,
      selectedTextFeedback,
      selectedSearchSubHeader,
      selectedSearchTemplate,
      rating,
      hoverRating,
      hanleMouseEnter,
      handleMouseLeave,
      handleSaveRating
    } = props;
    return (
      <>
        <div className="su__feed-rating su__font-10">
          <div className="su__feedback-label  su__my-2 su__font-11 su__color-lgray">
            {t(selectedSearchSubHeader)}
          </div>
          <Rating
            feedbackType={selectedSearchTemplate}
            size={40}
            stars={5}
            rating={rating}
            hoverRating={hoverRating}
            onMouseEnter={hanleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleSaveRating}
          />
        </div>
        {searchFeedbackToggle && (
          <div className="su__feed-txtarea">
            <div
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(selectedTextFeedback)}
              className="su__feedback-label  su__my-2 su__font-11 su__color-lgray"
            >
              {t(selectedTextFeedback)}
            </div>
            <div className="su__w-100">
              <textarea
                lang={variables.searchCallVariables.langAttr}
                name="feeback-txtarea"
                className="su__feedtext-area su__w-100 su__box-sizing"
                id="su__feedtext-area"
                rows="4"
                value={props.textAreaFeedback}
                onChange={(e) => props.settextAreaFeedback(e.target.value)}
                aria-label={t(StaticStrings.say_more)}
              ></textarea>
            </div>
          </div>
        )}
      </>
    );
  } catch (e) {
    console.log('Error in FeedbackSearch component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackSearch);
