import React from 'react';
import { useTranslation } from 'react-i18next';

const FeedbackTextual = (props) => {
  try {
    const { t } = useTranslation();
    let { selectedPageTextFeedback } = props;
    return (
      <>
        <div className="su__feed-desc  su__mt-2 su__font-11 su__color-lgray">
          {t(selectedPageTextFeedback)}
        </div>
        <div className="su__pagerating-box su__my-3">
          <textarea
            className="su__input-feedack su__form-control su__w-100 su__su__font-14 su__text-black su__border su__radius-2"
            type="text"
            name="su__pagerating-input"
            id="su__pagerating-input"
            rows='4'
            value={props.textInputFeedback}
            onChange={(e) => props.settextInputFeedback(e.target.value)}
          />
        </div>
      </>
    );
  } catch (e) {
    console.log('Error in FeedbackTextual component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackTextual);
