import React from 'react';
import { useTranslation } from 'react-i18next';
import StaticStrings from 'StaticStrings';
import { A11Y_HIDDEN, a11y, tabIndexes } from '../../../constants/a11y';
import variables from '../../../redux/variables';

const FeedbackFollowUP = (props) => {
  try {
    const { t } = useTranslation();
    let { selectedSearchFollowUp, huddleOnChange, isValid, isTextDisabled } = props;
    return (
      <div className="su__feed-radio su__mt-2">
        {/* <div
          lang={variables.searchCallVariables.langAttr}
          aria-label={t(selectedSearchFollowUp)}
          className="su__feedback-label su__my-2 su__font-11 su__color-lgray"
        >
          {t(selectedSearchFollowUp)}
        </div> */}
        {/* <div className="su__feedradio-group">
          <div className="su__feedradio-row su__my-2 su__flex-vcenter su__font-14">
            <input
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.su_yes)}
              tabIndex={tabIndexes.tabIndex_0}
              type="radio"
              id="su__feed-yes"
              name="su__feedback-follow"
              defaultChecked={props.isFollowUp}
              value={props.isFollowUp}
              onChange={() => props.setFollowUp(true)}
            />
            <label
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.su_yes)}
              role={a11y.ROLES.RADIO}
              className="su__feed-labels su__cursor su__font-12 su__position-relative su__mr-2"
              htmlFor="su__feed-yes"
            >
              {t(StaticStrings.su_yes)}
            </label>
            <input
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.su_no)}
              tabIndex={tabIndexes.tabIndex_0}
              type="radio"
              id="su__feed-no"
              name="su__feedback-follow"
              defaultChecked={!props.isFollowUp}
              value={!props.isFollowUp}
              onChange={() => props.setFollowUp(false)}
            />
            <label
              lang={variables.searchCallVariables.langAttr}
              aria-label={t(StaticStrings.su_no)}
              role={a11y.ROLES.RADIO}
              className=" su__feed-labels su__cursor su__font-12 su__position-relative su__mr-2"
              htmlFor="su__feed-no"
            >
              {t(StaticStrings.su_no)}
            </label>
          </div>
        </div> */}
        {/* {props.isFollowUp && ( */}
          <div className={'su__feed-email-box su__my-3'}>
            <label
              lang={variables.searchCallVariables.langAttr}
              className="su__feedback-label  su__my-2 su__font-11 su__color-lgray su__d-block"
            >
              {t(StaticStrings.email_text)}
            </label>
            <input
              lang={variables.searchCallVariables.langAttr}
              className="su__input-feedack su__form-control su__w-100 su__su__font-14 su__text-black su__border su__radius-2"
              type="email"
              name="su__feedback-email"
              id="su__feedback-email"
              disabled={isTextDisabled}
              aria-label={t(StaticStrings.email_text)}
              value={props.followupEmail}
              onInput={(e) => huddleOnChange(e.target.value)}
              onChange={(e) => props.setFollowupEmail(e.target.value)}
              onFocus={() => {
                /** changed aria-describedy approach to mitigate NVDA + firefox announcing twice issue as below*/
                const announce = document.getElementById('invalidEmailText');
                if (announce) {
                  announce.textContent = ''; // set the alert text to empty string
                  setTimeout(() => {
                    announce.textContent = isValid ? t(StaticStrings.invalid_email) : ''; // reset to actual value after a delay
                  }, 200);
                }
              }}
            />
            <span
              lang={variables.searchCallVariables.langAttr}
              aria-hidden="true"
              className="su__error-msg"
            >
              {isValid ? t(StaticStrings.invalid_email) : ''}
            </span>
            <div
              lang={variables.searchCallVariables.langAttr}
              id={'invalidEmailText'}
              aria-live="polite"
              aria-atomic="true"
              tabIndex={tabIndexes.tabIndex_minus_1}
              className={A11Y_HIDDEN}
            >
              {isValid ? t(StaticStrings.invalid_email) : ''}
            </div>
          </div>
        {/* )} */}
      </div>
    );
  } catch (e) {
    console.log('Error in FeedbackFollowUP component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackFollowUP);
