import React from 'react';
import { useTranslation } from 'react-i18next';

const FeedbackName = (props) => {
  const customPlaceholderText= ""; // Added custom placeholder text
  try {
    const { t } = useTranslation();
    let { selectedPageTextFeedback } = props;
    return (
      <>
        <div className="su__pagerating-box su__my-3">
        <label htmlFor="">Name</label>
          <input
            className="su__input-feedack su__form-control su__w-100 su__su__font-14 su__text-black su__border su__radius-2"
            type="text"
            name="su__pagerating-input"
            id="su__pagerating-input"
            type="name"
            value={props.textNameFeedback}
            onChange={(e) => props.settextNameFeedback(e.target.value)}
          />
        </div>
      </>
    );
  } catch (e) {
    console.log('Error in FeedbackTextual component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackName);