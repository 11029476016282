import React from 'react';
import { a11y, tabIndexes } from '../../../constants/a11y';
import StaticStrings from 'StaticStrings';
import { useTranslation } from 'react-i18next';
import variables from '../../../redux/variables';
import { v4 as uuid } from 'uuid';
import { func, number, string } from 'prop-types';

export function Rating({
  feedbackType,
  rating,
  hoverRating,
  onClick,
  onMouseEnter,
  onMouseLeave,
  stars = 5,
  pageRateFeed
}) {
  const { t } = useTranslation();
  return (
    <div className="su__star-wrapper">
      {feedbackType == 'Stars' && (
        <div className="su__feed-rating su__flex-vcenter su__font-10">
          {[...Array(stars)].map((_, index) => (
            <div
              className={`${
                (hoverRating || rating || pageRateFeed) &&
                (hoverRating || rating || pageRateFeed) > index
                  ? 'su__star-yellow'
                  : 'su__star-gray'
              }  su__star su__mr-2 su__cursor `}
              key={uuid()}
              onMouseEnter={() => onMouseEnter(index + 1)}
              onMouseLeave={() => onMouseLeave()}
              onClick={() => onClick(index + 1)}
            ></div>
          ))}
        </div>
      )}
      {feedbackType == 'Emoticons' && (
        <div className="su__feed-rating su__flex-vcenter su__font-10">
          {[...Array(stars)].map((_, index) => (
            <button
              lang={variables.searchCallVariables.langAttr}
              aria-label={`${t(StaticStrings.rating)} ${index + 1}`}
              role={a11y.ROLES.RADIO}
              tabIndex={tabIndexes.tabIndex_0}
              className={`${
                (hoverRating || rating || pageRateFeed) &&
                (hoverRating || rating || pageRateFeed) > index
                  ? 'su__emoji-active'
                  : ''
              } a11y-btn su__emoji su__mr-1 su__cursor su__emoji-icon${index} `}
              key={uuid()}
              onMouseEnter={() => onMouseEnter(index + 1)}
              onMouseLeave={() => onMouseLeave()}
              onClick={() => onClick(index + 1)}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
}

Rating.propTypes = {
  feedbackType: string,
  rating: string,
  hoverRating: string,
  onClick: func,
  onMouseEnter: func,
  onMouseLeave: func,
  stars: number,
  pageRateFeed: string
};

Rating.defaultProps = {
  feedbackType: '',
  rating: '',
  hoverRating: '',
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  stars: 1,
  pageRateFeed: ''
};
