import React from 'react';
import { Rating } from 'components/section-components/rating/index.jsx';

const FeedbackYesOrNo = (props) => {
  let {
    pageRateFeed,
    handlenewSaverating,
    starhanleMouseEnter,
    starhandleMouseLeave,
    starhoverRating
  } = props;
  try {
    let {
      selectedPageTemplete,
      selectedHeader,
      storeRate,
      storePageRatingFeedback,
      storePageRatingData
    } = props;
    return (
      <div className="su_feedback_form su__flex-vcenter su__feedbackform_custom">
        <div className="su__text-black su__flex-1 su__text-left su__font-12">{selectedHeader}</div>
        {selectedPageTemplete == 'Yes/No' && (
          <>
            <span
              className="su_helpful su__mx-3"
              onClick={() => (storeRate ? storePageRatingData(1) : storePageRatingFeedback(1))}
            >
              Yes
            </span>
            <span
              className="su_unhelpful"
              onClick={() => (storeRate ? storePageRatingData(0) : storePageRatingFeedback(0))}
            >
              No
            </span>
          </>
        )}
        {selectedPageTemplete == 'Thumbs up/Down' && (
          <>
            <span
              className="su_thumb-yes su__mx-3"
              onClick={() => (storeRate ? storePageRatingData(1) : storePageRatingFeedback(1))}
            >
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                className="su__fill-black su__cursor su__fillhover-blue"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z" />
              </svg>
            </span>
            <span
              className="su_thumb-no"
              onClick={() => (storeRate ? storePageRatingData(0) : storePageRatingFeedback(0))}
            >
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                className="su__fill-black su__cursor su__fillhover-blue"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z" />
              </svg>
            </span>
          </>
        )}
        {selectedPageTemplete == 'Stars' && (
          <>
            <span className="su_thumb-yes su__mx-3">
              <Rating
                feedbackType={selectedPageTemplete}
                size={40}
                stars={5}
                rating={pageRateFeed}
                hoverRating={starhoverRating}
                onMouseEnter={starhanleMouseEnter}
                onMouseLeave={starhandleMouseLeave}
                onClick={handlenewSaverating}
              />
            </span>
          </>
        )}
        {selectedPageTemplete == 'Emoticons' && (
          <>
            <span className="su_thumb-yes su__mx-3">
              <div>
                <Rating
                  feedbackType={selectedPageTemplete}
                  size={40}
                  stars={5}
                  rating={pageRateFeed}
                  hoverRating={starhoverRating}
                  onMouseEnter={starhanleMouseEnter}
                  onMouseLeave={starhandleMouseLeave}
                  onClick={handlenewSaverating}
                />
              </div>
            </span>
          </>
        )}
      </div>
    );
  } catch (e) {
    console.log('Error in FeedbackYesOrNo component', e);
    return <div></div>;
  }
};

export default React.memo(FeedbackYesOrNo);
