/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { pageRating } from './redux/ducks';
import { useDispatch, useSelector } from 'react-redux';
import variables from './redux/variables';
import Icons from './assets/svg-icon/svg';
import StaticStrings from 'StaticStrings';

import FeedbackConfirmation from 'components/section-components/feedback-confirmation/index.jsx';
import FeedbackYesOrNo from 'components/section-components/feedback-yes-no/index.jsx';
import FeedbackTextual from 'components/section-components/feedback-textual/index.jsx';
import FeedbackSearch from 'components/section-components/feedback-search/index.jsx';
import FeedbackFollowUP from 'components/section-components/feedback-followup/index.jsx';
import FeedbackName from 'components/custom-components/feedback-name-1741332658480/index.jsx';
import FeedbackEmail from 'components/custom-components/feedback-email-1741345891594/index.jsx';

const safeJsonParser = (input) => {
  if (typeof input !== 'string') return undefined;
  try {
    return JSON.parse(input);
  } catch (e) {
    return undefined;
  }
};

const App = () => {
  try {
    let pageRatingArray;
    let pageRatingInstanceArray;
    let searchpageRatingInstanceArray;
    const dispatch = useDispatch();
    let { contentSearchExp, pageRatingInstance, pageRatingCustomization, searchFeedback } =
      useSelector((state) => state.pageRatingResult);
    let { enabled } = contentSearchExp ? safeJsonParser(contentSearchExp) : '';
    let {
      selectedAck,
      selectedHeader,
      searchToggle,
      submitButton,
      selectedPostion,
      pageFeedbackToggle,
      selectedPageTemplete,
      selectedPageTextFeedback
    } = pageRatingCustomization ? safeJsonParser(pageRatingCustomization) : '';
    let {
      followUpToggle,
      searchEmailId,
      searchFeedbackToggle,
      selectedSearchFollowUp,
      selectedSearchHeader,
      selectedSearchAcknowledgement,
      selectedSearchSubHeader,
      selectedSearchTemplate,
      selectedTextFeedback
    } = searchFeedback ? safeJsonParser(searchFeedback) : '';
    pageRatingArray = pageRatingInstance && safeJsonParser(pageRatingInstance);
    pageRatingInstanceArray = pageRatingInstance && safeJsonParser(pageRatingInstance);
    searchpageRatingInstanceArray = pageRatingInstance && safeJsonParser(pageRatingInstance);
    const [yesNoRating, setYesNoRating] = useState(null);
    const [showhide, setshowhide] = useState(false);
    const [isThankyouShow, setThankyouShow] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [rating, setRating] = useState(0);
    const [ThankYouModal, setThankYouModal] = useState(true);
    const [pageRateFeed, setpageRateFeed] = useState(0);
    const [feedback_type, setfeedback_type] = useState(0);
    const [starhoverRating, setstarHoverRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [textInputFeedback, settextInputFeedback] = useState('');
    const [textNameFeedback, settextNameFeedback] = useState('');
    const [textEmailFeedback, settextEmailFeedback] = useState('');
    const [followupEmail, setFollowupEmail] = useState('');
    const [textAreaFeedback, settextAreaFeedback] = useState('');
    const [isFollowUp, setFollowUp] = useState(true);
    const [isEmailValidated, setIsEmailValidated] = useState(true);

    const [isValid, setIsValid] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [isTextDisabled] = useState(false);
    const emailRegex = new RegExp(
      /^[a-z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9]+(?:\.[a-z0-9]+)*\.[a-z]{2,6}$/i
    );
    const handleSaveRating = (rate) => {
      setRating(rate);
    };
    const handlenewSaverating = (rate) => {
      setpageRateFeed(rate);
      if (selectedSearchTemplate == 'Emoticons' || selectedSearchTemplate == 'Stars') {
        setfeedback_type(1);
      }
    };
    const starhanleMouseEnter = (index) => {
      setstarHoverRating(index);
    };
    const starhandleMouseLeave = () => {
      setstarHoverRating(0);
    };
    const hanleMouseEnter = (index) => {
      setHoverRating(index);
    };
    const handleMouseLeave = () => {
      setHoverRating(0);
    };
    const regexMatch = (pageRatingArray) => {
      if (pageRatingArray) {
        for (let i of pageRatingArray) {
          if (window.location.href && window.location.href.match(i['regex'])) {
            return true;
          }
        }
      }
      return false;
    };
    const updateLocalStorage = (articlesFeedbackArray, currentTime, currentLoc) => {
      let updated = false;
      const feedbackDelay = 1 * 24 * 60 * 60 * 1000;
      if (articlesFeedbackArray.length) {
        articlesFeedbackArray = articlesFeedbackArray.map((i) => {
          if (i.articleUrl === currentLoc) {
            i.articleTimeStamp = currentTime + feedbackDelay;
            updated = true;
          }
          return i;
        });
      }
      if (!updated) {
        articlesFeedbackArray.push({
          articleUrl: location.href,
          articleTimeStamp: currentTime + feedbackDelay
        });
      }
      localStorage.setItem('articlesSaved', JSON.stringify(articlesFeedbackArray));
    };

    const getLocalStorageData = () => {
      const articlesFeedbackArray = JSON.parse(localStorage.getItem('articlesSaved')) || [];
      const currentLoc = location.href;
      const currentTime = Date.now();
      return [articlesFeedbackArray, currentLoc, currentTime];
    };
    useEffect(() => {
      let [articlesFeedbackArray, currentLoc, currentTime] = getLocalStorageData();
      if (pageRateFeed === 0) {
        let thankYouFlag = false;
        if (articlesFeedbackArray.length) {
          const filteredArray = articlesFeedbackArray.filter((i) => i.articleUrl === currentLoc);
          if (filteredArray.length) {
            const [item] = filteredArray;
            if (item.articleTimeStamp > currentTime) {
              thankYouFlag = true;
              setThankYouModal(false);
            }
          }
        }
        setThankyouShow(thankYouFlag);
      } else if (pageRateFeed != 0 && !searchToggle && !pageFeedbackToggle) {
        updateLocalStorage(articlesFeedbackArray, currentTime, currentLoc);
        storePageRatingData(0);
      } else {
        setShowResults(true);
      }
    }, [pageRateFeed]);
    const instanceRegexMatch = (pageRatingInstanceArray) => {
      if (pageRatingInstanceArray) {
        for (let i of pageRatingInstanceArray) {
          if (
            typeof document.referrer != 'undefined' &&
            document.referrer.match(i['instance_regex'])
          ) {
            return true;
          }
        }
      }
      return false;
    };

    const searchinstanceRegexMatch = (searchpageRatingInstanceArray) => {
      if (searchpageRatingInstanceArray) {
        for (let value of searchpageRatingInstanceArray) {
          if (
            typeof document.referrer != 'undefined' &&
            document.referrer.match(value?.search_regex || '')
          ) {
            return true;
          }
        }
      }
      return false;
    };
    const commingFromSearch = searchinstanceRegexMatch(searchpageRatingInstanceArray);
    const storePageRatingFeedback = (val) => {
      setYesNoRating(val);
      let [articlesFeedbackArray, currentLoc, currentTime] = getLocalStorageData();
      if (pageFeedbackToggle) {
        setShowResults(!showResults);
      } else if (searchToggle && commingFromSearch) {
        setShowResults(!showResults);
      } else {
        updateLocalStorage(articlesFeedbackArray, currentTime, currentLoc);
        setThankyouShow(!isThankyouShow);
        gza('pagerating', {
          feedback: val,
          articlesFeedback: true,
          rating: pageRateFeed ? pageRateFeed : '',
          searchString:textInputFeedback,
          feedback_type: feedback_type,
          referer: document.referrer,
          window_url: window.location.href,
          articleFeedback: textInputFeedback,
          name:textNameFeedback,
          author:textEmailFeedback,
          uid: variables.searchCallVariables.uid
        });
      }
      setshowhide(false);
    };

    const huddleOnChange = (event) => {
      if (!emailRegex.test(event && followupEmail)) {
        setIsValid(true);
        setFollowupEmail(!followupEmail);
        setDisabled(false);
      } else {
        setIsValid(false);
        setFollowupEmail('');
        setDisabled(true);
      }
    };

    const handleEmailIsValidated = (userEmail) => {
        
        if (userEmail == '' || emailRegex.test(userEmail)) {
            setIsEmailValidated(true);
        } else {
            setIsEmailValidated(false);
        }
    };

    useEffect(() => {
      dispatch(pageRating.start(variables));
    }, []);

    const storePageRatingData = (val) => {
      setYesNoRating(val);
      let [articlesFeedbackArray, currentLoc, currentTime] = getLocalStorageData();
      if (pageFeedbackToggle) {
        setShowResults(!showResults);
      } else if (searchToggle && commingFromSearch) {
        setShowResults(!showResults);
      } else {
        updateLocalStorage(articlesFeedbackArray, currentTime, currentLoc);
        setThankyouShow(!isThankyouShow);
        gza('pagerating', {
          feedback: val,
          articlesFeedback: true,
          rating: pageRateFeed ? pageRateFeed : '',
          searchString:textInputFeedback,
          feedback_type: feedback_type,
          referer: document.referrer,
          window_url: window.location.href,
          articleFeedback: textInputFeedback,
          name:textNameFeedback,
          author:textEmailFeedback,
          uid: variables.searchCallVariables.uid
        });
        setRating(0);
        settextInputFeedback('');
        settextNameFeedback('');
        settextEmailFeedback('');
        setFollowupEmail('');
        settextAreaFeedback('');
        setshowhide(!showhide);
        setDisabled(false);
        setIsValid(false);
      }
    };
    const closeOverlay = () => {
      setShowResults(!showResults);
      setRating(0);
      settextInputFeedback('');
      settextNameFeedback('');
      settextEmailFeedback('');
      setFollowupEmail('');
      settextAreaFeedback('');
      setFollowUp(true);
      setDisabled(false);
      setIsValid(false);
    };
    const closeOverlayThank = () => {
      setThankYouModal(false);
      setRating(0);
      settextInputFeedback('');
      settextNameFeedback('');
      settextEmailFeedback('');
      setFollowupEmail('');
      settextAreaFeedback('');
      setFollowUp(true);
      setDisabled(false);
      setIsValid(false);
    };
    const txtFeedbackSubmit = () => {
      let [articlesFeedbackArray, currentLoc, currentTime] = getLocalStorageData();
      updateLocalStorage(articlesFeedbackArray, currentTime, currentLoc);
      setShowResults(!showResults);
      setThankyouShow(true);
      gza('pagerating', {
        feedback: yesNoRating,
        articlesFeedback: true,
        rating: pageRateFeed ? pageRateFeed : '',
        searchString:textInputFeedback,
        feedback_type: feedback_type,
        referer: document.referrer,
        articleFeedback: textInputFeedback,
        name:textNameFeedback,
        author:textEmailFeedback,
        window_url: window.location.href,
        uid: variables.searchCallVariables.uid
      });
      setRating(0);
      settextInputFeedback('');
      settextNameFeedback('');
      settextEmailFeedback('');
      setFollowupEmail('');
      settextAreaFeedback('');
      setFollowUp(true);
      setshowhide(!showhide);
      setDisabled(false);
    };
    // submit button feedback
    const feedbackSubmitForm = () => {
      let [articlesFeedbackArray, currentLoc, currentTime] = getLocalStorageData();
      updateLocalStorage(articlesFeedbackArray, currentTime, currentLoc);
      setShowResults(!showResults);
      setThankyouShow(true);
      gza('pagerating', {
        feedback: yesNoRating,
        articlesFeedback: true,
        rating: pageRateFeed ? pageRateFeed : '',
        searchString:textInputFeedback,
        feedback_type: feedback_type,
        referer: document.referrer,
        articleFeedback: textInputFeedback,
        name:textNameFeedback,
        author:textEmailFeedback,
        window_url: window.location.href,
        uid: variables.searchCallVariables.uid
      });
      if (commingFromSearch || !pageFeedbackToggle) {
        gza('searchfeedback', {
          feedback: textAreaFeedback ? textAreaFeedback : '',
          rating: rating ? rating : '',
          reported_by: isFollowUp ? followupEmail : '',
          conversion_url: window.location.href
        });
      }
      setRating(0);
      settextInputFeedback('');
      setFollowupEmail('');
      settextAreaFeedback('');
      setFollowUp(true);
      setshowhide(!showhide);
      setDisabled(false);
      setIsValid(false);
    };
    let classNameForPagerating =
      selectedPostion == 'Bottom' ? 'su__feedshow-bottom' : 'su__feedshow-center su__zindex-3';
    let classNameForRatingBox = isValid ? 'su__formError' : 'su__formnot-error';
    let classNameForOverlay =
      selectedPostion == 'Center'
        ? 'su__feedshow-overlay su__d-block su__zindex-1'
        : 'su__feedshow-overlay su__d-none';
    let classNameForFeedbackrow =
      selectedPostion == 'Bottom' ? 'su__feedshow-bottom' : 'su__feedshow-center su__zindex-3';
    let disableConditionForFeedbackSubmitButton = !(
      rating ||
      textInputFeedback ||
      textAreaFeedback ||
      (followupEmail && isDisabled)
    );
    let classNameForCloseoverlay =
      selectedPostion == 'Center'
        ? 'su__feedshow-overlay su__d-block su__zindex-1'
        : 'su__feedshow-overlay su__d-none';
    return (
      <div>
        {enabled && (
          <>
            {instanceRegexMatch(pageRatingInstanceArray) && (
              <>
                {regexMatch(pageRatingArray) && (
                  <div className={`su_page_rating_box ${classNameForRatingBox}`}>
                    {!isThankyouShow && (
                      <>
                        {!showhide && (
                          <>
                            <FeedbackYesOrNo
                              starhoverRating={starhoverRating}
                              starhandleMouseLeave={starhandleMouseLeave}
                              starhanleMouseEnter={starhanleMouseEnter}
                              handlenewSaverating={handlenewSaverating}
                              pageRateFeed={pageRateFeed}
                              hoverRating={hoverRating}
                              handleMouseLeave={handleMouseLeave}
                              hanleMouseEnter={hanleMouseEnter}
                              feedbackSubmitForm={feedbackSubmitForm}
                              handleSaveRating={handleSaveRating}
                              selectedPageTemplete={selectedPageTemplete}
                              selectedHeader={selectedHeader}
                              storeRate={searchFeedbackToggle || searchToggle}
                              storePageRatingData={storePageRatingData}
                              storePageRatingFeedback={storePageRatingFeedback}
                              rating={rating}
                            />
                          </>
                        )}

                        {showResults && pageFeedbackToggle && !searchToggle && (
                          <>
                            <div
                              className={`${classNameForPagerating} su__pagerating-row su__py-2 su__px-3 su__border su__position-relative`}
                            >
                              <div
                                className="su__close-svg su__position-absolute su__flex-vcenter su__bg-light-gray  su__bg-white-circle su__radius-50"
                                onClick={() => {
                                  setShowResults(!showResults) || setpageRateFeed(0);
                                }}
                              >
                                <Icons
                                  className="su__close-icon su__cursor"
                                  IconName="Close"
                                  width="12"
                                  height="12"
                                  color="#919bb0"
                                />
                              </div>
                              {pageFeedbackToggle && (
                                 <>
                                <FeedbackTextual
                                  selectedPageTextFeedback={selectedPageTextFeedback}
                                  storePageRatingFeedback={storePageRatingFeedback}
                                  textInputFeedback={textInputFeedback}
                                  settextInputFeedback={settextInputFeedback}
                                />
                                <span className="su__optional_text">{StaticStrings.follow_up_optional_text}</span>
                                <FeedbackName
                                  selectedPageTextFeedback={selectedPageTextFeedback}
                                  storePageRatingFeedback={storePageRatingFeedback}
                                  textNameFeedback={textNameFeedback}
                                  settextNameFeedback={settextNameFeedback}
                                />
                                <FeedbackEmail
                                  selectedPageTextFeedback={selectedPageTextFeedback}
                                  storePageRatingFeedback={storePageRatingFeedback}
                                  textEmailFeedback={textEmailFeedback}
                                  settextEmailFeedback={settextEmailFeedback}
                                  handleEmailIsValidated={handleEmailIsValidated}
                                />
                                {!isEmailValidated && textEmailFeedback && (
                                    <p className="feedback-email-validation-error" style={{ color: 'red' }}>Please enter a valid email address.</p>
                                )}
                                </>
                              )}
                              <button disabled={!isEmailValidated}
                                onClick={txtFeedbackSubmit}
                                className="su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__px-2 su__py-1 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue"
                              >
                                {submitButton}
                              </button>
                            </div>
                            <div onClick={closeOverlay} className={`${classNameForOverlay}`}></div>
                          </>
                        )}
                        {showResults && searchToggle && (
                          <>
                            <div
                              className={`${classNameForFeedbackrow} su__pagerating-row su__py-2 su__px-3 su__border su__position-relative`}
                            >
                              <div className="su__feedback-row">
                                <h2 className="su__feed-title su__pr-3 su__font-16 su__f-normal su__my-0">
                                  {selectedSearchHeader}
                                </h2>
                              </div>
                              <div
                                className="su__close-svg su__position-absolute su__flex-vcenter su__bg-light-gray  su__bg-white-circle su__radius-50"
                                onClick={() => {
                                  setShowResults(!showResults);
                                }}
                              >
                                <Icons
                                  className="su__close-icon su__cursor"
                                  IconName="Close"
                                  width="12"
                                  height="12"
                                  color="#919bb0"
                                />
                              </div>
                              {pageFeedbackToggle && (
                                <FeedbackTextual
                                  selectedPageTextFeedback={selectedPageTextFeedback}
                                  storePageRatingFeedback={storePageRatingFeedback}
                                  textInputFeedback={textInputFeedback}
                                  settextInputFeedback={settextInputFeedback}
                                />
                              )}

                              {searchToggle && commingFromSearch && (
                                <>
                                  <FeedbackSearch
                                    textAreaFeedback={textAreaFeedback}
                                    settextAreaFeedback={settextAreaFeedback}
                                    searchFeedbackToggle={searchFeedbackToggle}
                                    selectedTextFeedback={selectedTextFeedback}
                                    selectedSearchSubHeader={selectedSearchSubHeader}
                                    selectedSearchTemplate={selectedSearchTemplate}
                                    rating={rating}
                                    hoverRating={hoverRating}
                                    hanleMouseEnter={hanleMouseEnter}
                                    handleMouseLeave={handleMouseLeave}
                                    handleSaveRating={handleSaveRating}
                                  />
                                  {followUpToggle && (
                                    <FeedbackFollowUP
                                      isTextDisabled={isTextDisabled}
                                      isFollowUp={isFollowUp}
                                      setFollowUp={setFollowUp}
                                      searchEmailId={searchEmailId}
                                      selectedSearchFollowUp={selectedSearchFollowUp}
                                      followupEmail={followupEmail}
                                      setFollowupEmail={setFollowupEmail}
                                      huddleOnChange={huddleOnChange}
                                    />
                                  )}{' '}
                                </>
                              )}

                              {pageFeedbackToggle && !commingFromSearch && (
                                <button
                                  disabled={
                                    !(
                                      rating ||
                                      !commingFromSearch ||
                                      textAreaFeedback ||
                                      followupEmail
                                    )
                                  }
                                  onClick={feedbackSubmitForm}
                                  className="111 su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__px-2 su__py-1 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue"
                                >
                                  {submitButton}
                                </button>
                              )}
                              {searchToggle && pageFeedbackToggle && commingFromSearch && (
                                <button
                                  disabled={disableConditionForFeedbackSubmitButton}
                                  onClick={feedbackSubmitForm}
                                  className="222 su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__px-2 su__py-1 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue"
                                >
                                  {submitButton}
                                </button>
                              )}
                              {searchToggle && !pageFeedbackToggle && commingFromSearch && (
                                <button
                                  disabled={disableConditionForFeedbackSubmitButton}
                                  onClick={feedbackSubmitForm}
                                  className="444 su__feedback-btn su__hover-bg-blue su__text-white-hover su__font-12 su__mt-1 su__px-2 su__py-1 su__radius su__bg-white su__border su__btn su__border_skyblue su__text-blue"
                                >
                                  {submitButton}
                                </button>
                              )}
                            </div>
                            <div
                              onClick={closeOverlay}
                              className={`${classNameForCloseoverlay}`}
                            ></div>
                          </>
                        )}
                      </>
                    )}
                    {isThankyouShow && !ThankYouModal && (
                      <>
                        <div className="su__feedshow-bottom su__pagerating-lay">
                          <FeedbackConfirmation
                            selectedAck={selectedAck}
                            searchToggle={searchToggle}
                            selectedSearchAcknowledgement={selectedSearchAcknowledgement}
                            ThankYouModal={ThankYouModal}
                          />
                        </div>
                        {/* <div onClick={closeOverlay, closeOverlayThank} className={`${selectedPostion == 'Center' ? 'su__feedshow-overlay su__d-block su__zindex-1' : 'su__feedshow-overlay su__d-none'}`}></div> */}
                      </>
                    )}
                    {isThankyouShow && ThankYouModal && (
                      <>
                        <div
                          className={`${
                            selectedPostion == 'Bottom'
                              ? 'su__feedshow-bottom'
                              : 'su__feedshow-center su__zindex-3'
                          } su__pagerating-lay`}
                        >
                          {selectedPostion != 'Bottom' && (
                            <div
                              className="su__close-svg su__position-absolute su__flex-vcenter su__bg-light-gray  su__bg-white-circle su__radius-50"
                              onClick={closeOverlayThank}
                            >
                              <Icons
                                className="su__close-icon su__cursor"
                                IconName="Close"
                                width="12"
                                height="12"
                                color="#919bb0"
                              />
                            </div>
                          )}
                          <FeedbackConfirmation
                            selectedAck={selectedAck}
                            searchToggle={searchToggle}
                            selectedSearchAcknowledgement={selectedSearchAcknowledgement}
                          />
                        </div>
                        <div
                          onClick={() => {
                            closeOverlay();
                            closeOverlayThank();
                          }}
                          className={`${
                            selectedPostion == 'Center'
                              ? 'su__feedshow-overlay su__d-block su__zindex-1'
                              : 'su__feedshow-overlay su__d-none'
                          }`}
                        ></div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  } catch (e) {
    console.log('Error in page rating component', e);
    return <div></div>;
  }
};

export default App;